.content-navbar {
  height: 60px;
  background-color: #f5f3f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   fixed navbar style
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  // .head-Image {
  //   padding: 0 20px;
  //   color: #ffffff;
  //   text-decoration: none;
  //   display: flex;
  //   align-items: center;
  //   width: 20%;
  //   &:hover {
  //     background-color: transparent;
  //   }

  //   @media (max-width: 600px) {
  //     width: 40%;
  //   }
  // }
  .head-Image {
    padding: 0 20px;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 15%;
    img {
      width: 100%;
      cursor: pointer;
    }
    &:hover {
      background-color: transparent;
    }

    @media (max-width: 600px) {
      width: 40%;
      img {
        width: 100%;
      }
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
      width: 20%;
      img {
        width: 100%;
      }
    }
  }

  a {
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: #1c2938;
    text-decoration: none;

    &:hover {
      background-color: #0066ee;
      border-radius: 5px;
      color: #f5f3f7;
    }
  }

  .dropdown-desktop {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .dropdown-mobile {
    display: none;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        padding: 20px 30px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  .dropdown-link {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;

    &:hover {
      background-color: transparent;
    }
  }

  svg {
    fill: #1c2938;
  }
}

.open-sidebar-button,
.close-sidebar-button {
  display: none;

  @media (max-width: 600px) {
    display: block;
    padding: 20px;
  }
}

#sidebar-active {
  display: none;
}

.links-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 10;
    width: 300px;
    height: 100%;
    background-color: #f5f3f7;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
    transition: 0.75s ease-out;
  }

  @media (min-width: 601px) {
    .dropdown-mobile {
      display: none;
    }
  }
}

.content-navbar a {
  box-sizing: border-box;
  height: auto;
  width: 100%;
  padding: 15px 30px;
  justify-content: flex-start;

  @media (min-width: 601px) {
    display: none;
  }
}

#sidebar-active:checked ~ .links-container {
  right: 0;
}

#sidebar-active:checked ~ #overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

// active link background
.links-container a.active-link {
  background-color: rgba(244, 109, 37, 0.12);
  // color: white;
  border-radius: 5px;
}
