.homePageMaindiv {
  background-color: #01060c;
  .headSection {
    position: relative;
    .headimagediv {
      width: 100%;
      height: 600px;
      box-sizing: border-box;
      overflow: hidden;
      @media (max-width: 600px) {
        height: 100%;
      }

      img {
        width: 100%;
        opacity: 0.5;
        object-fit: cover;
        object-position: center;
        @media (max-width: 600px) {
          margin-top: 60px;
        }
      }
      @media screen and (min-width: 601px) and (max-width: 768px) {
        height: 100%;
      }
    }
    .headTextRight {
      width: 40%;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: 15px;

      .headTitleText {
        color: #f5f3f7;
        font-weight: bold;
        margin: 0;
        font-size: 55px;
      }
      .headParaText {
        color: #f5f3f7;
        font-size: 18px;
      }
      @media (max-width: 600px) {
        width: 62%;
        margin-top: 43%;
        text-align: right;
        .headTitleText {
          font-size: 22px;
        }
        .headParaText {
          font-size: 12px;
          text-align: right;
        }
        .right_contactbtn {
          display: none;
        }
      }
      @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 50%;
        margin-top: 40%;
        .headTitleText {
          font-size: 28px;
        }
        .headParaText {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
  .flexible_plug-div {
    padding: 50px 0;
    .headTitle {
      font-size: 55px;
      font-weight: bold;
      color: #f5f3f7;
      text-align: center;
      margin: 0;
      @media (max-width: 600px) {
        font-size: 22px;
      }
      @media screen and (min-width: 601px) and (max-width: 768px) {
        font-size: 28px;
      }
    }
    .flexibleplug-content-Mainbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 10px;
      text-align: center;
      margin-top: 10px;
      .contentBox {
        border: 1px solid #f5f3f7;
        border-radius: 10px;
        width: 30%;
        background-color: #13232f;
        padding: 5px;
        transition: transform 0.5s ease;
        &:hover {
          transform: scale(1.03);
        }
        h4 {
          color: #ff6a1a;
          margin-bottom: 0.3em;
          align-items: center;
        }
        .flexbleplug-para {
          color: #f5f3f7;
          font-size: 15px;
        }
        @media (max-width: 600px) {
          width: 80%;
          margin-top: 5px;
        }
        @media screen and (min-width: 601px) and (max-width: 768px) {
          width: 40%;
        }
      }
    }
  }
  .sakshamMaindiv {
    background-color: #13232f;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 50px 0px;
    align-items: center;
    .sakshamImagesBox {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      width: 46%;
      .sakshamImageContainer {
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid #f5f3f7;
        .sakshamImage {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .sakshamContentBox {
      width: 50%;
      align-items: center;

      .sakshamTitleText {
        color: #ff6a1a;
        font-weight: bold;
        text-align: right;
        font-size: 65px;
      }
      .sakshamPara {
        color: #f5f3f7;
        font-size: 21px;
        text-align: right;
      }
    }
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      .sakshamImagesBox {
        width: 80%;
        .sakshamImageContainer {
          width: 100%;
        }
      }
      .sakshamContentBox {
        width: 90%;
        .sakshamTitleText {
          text-align: center;
          font-size: 35px;
        }
        .sakshamPara {
          text-align: center;
          font-size: 16px;
        }
      }
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
      .sakshamImagesBox {
        width: 80%;
        .sakshamImageContainer {
          width: 100%;
        }
      }
      .sakshamContentBox {
        width: 80%;
        .sakshamTitleText {
          text-align: center;
          font-size: 38px;
        }
        .sakshamPara {
          text-align: center;
          font-size: 18px;
        }
      }
    }
  }

  // ***************Delivering States Map****************
  .deliveringStatusMapMainBox {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    padding: 50px 0;
    .leftside {
      width: 40%;
      .title {
        color: #f5f3f7;
        font-size: 55px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 0;
      }
      .availableMainBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
        .avaialabity {
          color: #ff6a1a;
          margin: 0;
          font-weight: bold;
          font-size: 55px;
        }
        .text {
          color: #f5f3f7;
          margin: 0;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
    .Rightsidemap {
      box-sizing: border-box;
      height: 450px;
      overflow: hidden;
      width: 55%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    @media (max-width: 600px) {
      .leftside {
        width: 100%;
        .title {
          font-size: 22px;
          text-align: center;
        }
        .availableMainBox {
          display: flex;
          justify-content: space-evenly;
          margin-top: 10px;
          .avaialabity {
            font-size: 22px;
          }
          .text {
            font-size: 16px;
          }
        }
      }
      .Rightsidemap {
        width: 100%;
      }
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
      .leftside {
        width: 100%;
        .title {
          font-size: 28px;
          text-align: center;
        }
        .availableMainBox {
          display: flex;
          justify-content: space-evenly;
          margin-top: 10px;
          .avaialabity {
            font-size: 28px;
          }
          .text {
            font-size: 18px;
          }
        }
      }
      .Rightsidemap {
        width: 100%;
      }
    }
  }

  // ******************whychooseus**********************
  .why-chooseus_div {
    background-color: #13232f;
    padding: 50px 0px;

    .whychooseusTitle {
      font-size: 55px;
      font-weight: bold;
      text-align: center;
      color: #f5f3f7;
      margin: 0;
    }
    .whychoose_ContentBox {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 10px;
      .box {
        width: 250px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 200px;
        align-items: center;
        padding: 10px;
        text-align: center;
        border: 1px solid #f5f3f7;
        border-radius: 10px;
        background-color: #01060c;
        transition: transform 0.5s ease;
        &:hover {
          transform: scale(1.03);
        }
        .whychooseimages {
          width: 30%;
          color: #ff6a1a;
          filter: invert(50%) sepia(46%) saturate(7344%) hue-rotate(3deg)
            brightness(101%) contrast(107%);
        }
        .whychooseText {
          color: #f5f3f7;
        }
      }
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
      .whychooseusTitle {
        font-size: 28px;
      }
      .whychoose_ContentBox {
        .box {
          width: 210px;
          height: 170px;
        }
      }
    }
    @media (max-width: 600px) {
      .whychooseusTitle {
        font-size: 22px;
      }
      .whychoose_ContentBox {
        .box {
          width: 220px;
          height: 180px;
        }
      }
    }
  }
  // ****************************Delivering excellence***************************

  .delivering_MainBox {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .deliveringTitle {
      font-size: 55px;
      margin: 0;
      text-align: center;
      color: #f5f3f7;
      font-weight: bold;
    }
    .deliveringpara {
      font-size: 21px;
      color: #f5f3f7;
      text-align: center;
      margin: auto;
      width: 80%;
    }
    .videoMainBox {
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 20px;
      .antrow {
        width: 90%;
        .Leftside {
          .videoPara {
            color: #f5f3f7;
            font-weight: 600;
            font-size: 22px;
            margin: 0;
          }
          .ceoName {
            color: #ff6a1a;
            font-weight: bold;
            font-size: 22px;
            text-align: center;
          }
        }
        .videoContentRightside {
          video {
            height: 300px;
            border-radius: 10px;
            width: 90%;
          }
          .videoDetails {
            color: #ff6a1a;
            a {
              color: #ff6a1a;
            }
          }
        }
      }
    }

    @media screen and (min-width: 601px) and (max-width: 768px) {
      .deliveringTitle {
        font-size: 28px;
      }
      .deliveringpara {
        font-size: 18px;
      }
      .videoMainBox {
        .antrow {
          text-align: center;
          .Leftside {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .videoPara {
              width: 100%;
              font-size: 18px;
            }
            .ceoName {
              font-size: 18px;
            }
          }
          .videoContentRightside {
            video {
              width: 70%;
            }
          }
        }
      }
    }
    @media (max-width: 600px) {
      .deliveringTitle {
        font-size: 22px;
      }
      .deliveringpara {
        font-size: 16px;
      }
      .videoMainBox {
        .antrow {
          text-align: center;
          .Leftside {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .videoPara {
              width: 100%;
              font-size: 18px;
            }
            .ceoName {
              font-size: 18px;
            }
          }
          .videoContentRightside {
            video {
              width: 100%;
            }
          }
        }
      }
    }
  }
  // ******************************Frequently asked questions***********************************

  .frequentlyasked_questionsBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #13232f;
    padding: 50px 0;
    .faqTitle {
      font-size: 55px;
      margin: 0;
      text-align: center;
      color: #f5f3f7;
      font-weight: bold;
    }
    .homepageCollapse {
      width: 80%;
      margin: 10px auto;
      border: none;
    }
    @media (max-width: 600px) {
      .faqTitle {
        font-size: 22px;
      }
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
      .faqTitle {
        font-size: 28px;
      }
    }
  }

  // ***********************************Footer Main div************************************
  .footerMaindiv {
    position: relative;
    .footerImagediv {
      width: 100%;
      height: 55vh;
      box-sizing: border-box;
      overflow: hidden;
      @media (max-width: 600px) {
        height: 100%;
      }

      img {
        width: 100%;
        opacity: 0.5;
        object-fit: cover;
        object-position: center;
      }
    }
    .footerAddress {
      bottom: 0;
      right: 50px;
      position: absolute;
      width: 20%;
      margin-bottom: 60px;
      .address {
        .addressTitle {
          color: #ff6a1a;
          margin: 0;
        }
        .addressDetails {
          color: #f5f3f7;
          font-weight: 600;
          margin: 0;
          font-size: 14px;
        }
      }
      .contactdiv {
        .contactus {
          color: #ff6a1a;
          margin: 0;
        }
        .mail {
          margin: 0;
          font-size: 14px;
          a {
            color: #f5f3f7;
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      .googleplay {
        width: 100%;
        margin-top: 5px;
        img {
          width: 50%;
        }
      }
      @media (max-width: 600px) {
        width: 43%;
        right: 10px;
        .address {
          .addressTitle {
            font-size: 14px;
          }
          .addressDetails {
            font-size: 12px;
          }
        }
        .contactdiv {
          .contactus {
            font-size: 14px;
          }
          .mail {
            a {
              font-size: 12px;
            }
          }
        }
      }
      @media screen and (min-width: 601px) and (max-width: 768px) {
        width: 30%;
        right: 10px;
      }
    }
    .endContent {
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;

      .privacypolicy {
        text-align: center;
        color: #ff6a1a;
        margin: 0;
        cursor: pointer;
      }
      .copyright {
        color: #f5f3f7;
        font-weight: 600;
        font-size: 14px;
      }
      @media (max-width: 600px) {
        .privacypolicy {
          font-size: 14px;
        }
        .copyright {
          font-size: 12px;
        }
      }
    }
  }
}
