.LoginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.LoginForm {
  width: 40%;
}

.inputbox {
  margin-top: 10px;
}
.loginBtn {
  margin-top: 10px;
}
@media (max-width: 600px) {
  .LoginForm {
    width: 80%;
  }
}
