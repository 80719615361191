.layout-Maindiv {
  display: flex;
  flex-direction: row;
  margin-top: 65px;
  @media (max-width: 600px) {
    padding: 20px;
  }
}

.no-margin {
  margin: 0;
}

.sidebar {
  width: 20%;
  display: block;

  @media (max-width: 600px) {
    display: none;
  }
}

.main-content {
  width: 80%;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.full-width-content {
  width: 100%;
  box-sizing: border-box;
}

// card css
.ant-card .ant-card-body {
  padding: 10px;
}
