.heading {
  margin: 0 !important;
  @media (max-width: 600px) {
    font-size: 21px !important;
  }
}
.AssignDa-mainBox {
  height: 600px;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 0px 10px;
  @media (max-width: 600px) {
    height: 600px;
    padding: 0;
  }
}
