.heading {
  margin: 0 !important;
  @media (max-width: 600px) {
    font-size: 21px !important;
  }
}

// individual data css
table {
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
}
