.orderHistory {
  .heading {
    margin: 0 !important;
    @media (max-width: 600px) {
      font-size: 21px !important;
    }
  }
  .date_And_orderstatus {
    @media (max-width: 600px) {
      margin-top: 10px !important;
    }
  }

  .orderDeliveryReq-mainBox {
    height: 600px;
    overflow-y: scroll;
    margin-top: 10px;
    @media (max-width: 600px) {
      height: 600px;
    }
  }
}

// Entire order data css

.OrderData {
  .trackingStatus {
    height: 600px;
    overflow-y: scroll;
    @media (max-width: 600px) {
      height: 400px;
    }
  }
}
