body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.downloadCSVbtn {
  @media (max-width: 600px) {
    display: none;
  }
}

/* button */
.ant-btn-default {
  background-color: #ff6a1a;
  color: #ffffff;
}
.ant-input-search .ant-input-search-button {
  background-color: transparent;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(62, 56, 56);
  border-radius: 10px;
}

/************************ steps ***************************/

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ff6a1a;
}

/* current step */
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #ff6a1a;
  border-color: #ff6a1a;
}

/* vertical line width */
/* .ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  width: 2px;
} */

/* finished background */
/* .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #ff6a1a;
} */

/* line background */
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #ff6a1a;
}

/* *******************Segmented tabs ************************** */
.ant-segmented .ant-segmented-item-selected {
  background-color: #ff6a1a;
  color: white;
}

/* collapse */

/* .ant-collapse > .ant-collapse-item {
  border: 1px solid #f5f3f7;
  margin: 10px 0;
  border-radius: 5px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #ff6a1a;
  background-color: #01060c;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}
.ant-collapse .ant-collapse-content {
  background-color: #13232f;
  color: #f5f3f7;
  border-radius: 5px;
}
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 5px;
}
.ant-collapse .ant-collapse-item:last-child {
  border: 1px solid #f5f3f7;
  border-radius: 5px;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  font-size: 14px;
}
@media (max-width: 600px) {
} */

/* collapse */
.homepageCollapse {
  &.ant-collapse {
    /* border: none; */
    > .ant-collapse-item {
      border: 1px solid #f5f3f7;
      margin: 10px 0;
      border-radius: 5px;
    }
    > .ant-collapse-item > .ant-collapse-header {
      color: #ff6a1a;
      background-color: #01060c;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
    }
    .ant-collapse-content {
      background-color: #13232f;
      color: #f5f3f7;
      border-radius: 5px;
    }
    > .ant-collapse-item:last-child > .ant-collapse-header {
      border-radius: 5px;
    }
    .ant-collapse-item:last-child {
      border: 1px solid #f5f3f7;
      border-radius: 5px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
  }
}
