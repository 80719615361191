.privacyPolicyMainDiv {
  background-color: #13232f;
  padding: 50px 20px;
  .TitleText {
    color: #ff6a1a;
    text-align: center;
    font-weight: bold;
    font-size: 55px;
    margin: 0;
  }
  .privacyContent {
    margin-top: 20px;
    .paragraph {
      color: #f5f3f7;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.27;
      .mailTo {
        color: #ff6a1a;
      }
    }
    .sideheading {
      font-size: 26px;
      color: #f5f3f7;
      font-weight: bold;
    }
  }
  @media (max-width: 600px) {
    padding: 30px 20px;
    .TitleText {
      font-size: 22px;
      margin-top: 30px;
    }
    .privacyContent {
      .paragraph {
        font-size: 12px;
      }
      .sideheading {
        font-size: 16px;
      }
    }
  }
  @media screen and (min-width: 601px) and (max-width: 768px) {
    padding: 30px 20px;
    .TitleText {
      font-size: 24px;
      margin-top: 30px;
    }
    .privacyContent {
      .paragraph {
        font-size: 14px;
      }
      .sideheading {
        font-size: 18px;
      }
    }
  }
}
