.contactusMainPage {
  background-color: #13232f;
  padding: 60px 20px;
  height: 100vh;
  .TitleText {
    color: #f5f3f7;
    text-align: center;
    font-weight: bold;
    font-size: 55px;
    margin: 0;
    @media (max-width: 600px) {
      font-size: 22px;
      margin-top: 30px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }
  .sentence {
    color: #f5f3f7;
    text-align: center;
    font-size: 21px;
    line-height: 1.27;
    @media (max-width: 600px) {
      font-size: 16px;
      margin-top: 10px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
      font-size: 18px;
    }
  }
  .contactForm {
    background-color: #01060c;
    display: flex;
    padding: 80px 100px;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    .formValues {
      .inputText {
        color: #13232f;
      }
      .textarea {
        color: #13232f;
      }
    }
    @media (max-width: 600px) {
      padding: 50px 20px;
    }
    @media screen and (min-width: 601px) and (max-width: 768px) {
      padding: 50px;
    }
  }
}
