.heading {
  margin: 0 !important;
  @media (max-width: 600px) {
    font-size: 21px !important;
  }
}
.date_And_ordestatus {
  @media (max-width: 600px) {
    margin-top: 10px !important;
  }
}

.orderDeliveryReq-mainBox {
  height: 600px;
  overflow-y: scroll;
  margin-top: 10px;
  @media (max-width: 600px) {
    height: 600px;
  }
}

.nameAndNumber {
  @media (max-width: 600px) {
    display: none;
  }
}
.customerDetails {
  @media (max-width: 600px) {
    display: none;
  }
}
