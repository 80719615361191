.homeNavbar {
  height: 60px;
  background-color: #01060c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   fixed navbar style
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  .homenav-head-Image {
    padding: 0 20px;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 50%;
    img {
      width: 20%;
      cursor: pointer;
    }
    &:hover {
      background-color: transparent;
    }

    @media (max-width: 600px) {
      width: 60%;
      img {
        width: 40%;
      }
    }
  }

  a {
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: #f5f3f7;
    text-decoration: none;

    &:hover {
      background-color: #0066ee;
      border-radius: 5px;
      color: #f5f3f7;
    }
  }

  // .route-links {
  //   display: flex;
  //   align-items: center;
  //   color: #f5f3f7;
  //   text-decoration: none;
  //   transition: transform 0.3s ease;
  //   &:hover {
  //     transform: scale(1.09);
  //     font-weight: 500;
  //   }
  // }

  .homenav-dropdown-desktop {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .dropdown-mobile {
    display: none;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        padding: 20px 30px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  .dropdown-link {
    display: flex;
    align-items: center;
    color: #f5f3f7;
    text-decoration: none;

    &:hover {
      background-color: transparent;
    }
  }

  svg {
    fill: #f5f3f7;
  }
}

.homenav-open-sidebar-button,
.homenav-close-sidebar-button {
  display: none;
  @media (max-width: 600px) {
    display: block;
    padding: 20px;
  }
}

#homenav-sidebar-active {
  display: none;
}

.homenav-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  // display: none;
  @media (max-width: 600px) {
    // display: block;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 10;
    width: 300px;
    height: 100%;
    background-color: #01060c;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
    transition: 0.75s ease-out;
  }

  @media (min-width: 601px) {
    .dropdown-mobile {
      display: none;
    }
  }
}

.homeNavbar a {
  box-sizing: border-box;
  height: auto;
  width: 100%;
  padding: 15px 30px;
  justify-content: flex-start;

  @media (min-width: 601px) {
    display: none;
  }
}

#homenav-sidebar-active:checked ~ .homenav-links-container {
  right: 0;
}

#homenav-sidebar-active:checked ~ #overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

// active link background
.homenav-links-container a.active-link {
  background-color: rgba(244, 109, 37, 0.12);
  // color: white;
  border-radius: 5px;
}

// roverlogin-button styles
.Rover-loginbtndiv {
  .roverloginBtn {
    img {
      width: 15%;
      height: 80%;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
}

// mobile
.mobileRoverloginbtn {
  img {
    width: 15%;
    height: 80%;
  }
}
