.heading {
  margin: 0 !important;
  @media (max-width: 600px) {
    font-size: 21px !important;
  }
}

.deliveryReq-mainBox {
  height: 600px;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 0px 10px;
  @media (max-width: 600px) {
    height: 600px;
    padding: 0px;
  }
}

.nameAndNumber {
  @media (max-width: 600px) {
    display: none;
  }
}

.vehicle-image-container {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  transition: border 0.3s;
}

.vehicle-image-container.selected {
  border: 2px solid #ff6a1a;
}
